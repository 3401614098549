<template>
  <div v-if="hasBadge && badge" class="featured-icon">
    {{ badge }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    hasBadge: Boolean,
  },
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    badge() {
      return this.currentDirectory.badge_text;
    },
  },
};
</script>
